<template>
  <div>
    <div class="nav">
      <h1>资料管理/岗位管理</h1>
    </div>
    <div class="search">
      <div class="left">
        <div class="box">
          <el-input
            placeholder="请输入内容"
            style="width:300px"
            v-model="con.merchantName"
            clearable
            class="input-with-select"
          >
            <template slot="prepend">分类名称</template>
          </el-input>
        </div>
      </div>
      <div class="right">
        <el-button type="primary" @click="saveProblem()">新增数据</el-button>
      </div>
    </div>

    <div class="result">
      <span></span>
      <p>查询结果</p>
    </div>

    <div class="table">
      <el-table :data="tableData" style="width: 100%" header-cell-style="background: #f5f7fa;">
        <el-table-column align="center" prop="title" :show-overflow-tooltip="true" label="岗位名称" fixed="left"></el-table-column>
        <el-table-column align="center" prop="con" :show-overflow-tooltip="true" label="岗位内容"></el-table-column>
        <el-table-column align="center" prop="createtime" width="170" label="创建时间"></el-table-column>
        <!-- <el-table-column align="center" prop="sortOrder" label="排序" width="100"></el-table-column> -->
        <el-table-column align="center" prop="address" label="操作" fixed="right">
          <template slot-scope="scope">
            <div class="flex">
              <el-tooltip
                class="item"
                @click.native="editNews(scope.row)"
                effect="dark"
                content="编辑产品"
                placement="top"
              >
                <p class="table-btn">
                  <i class="el-icon-edit"></i>
                </p>
              </el-tooltip>

              <el-tooltip
                class="item"
                @click.native="delList(scope.row.id)"
                effect="dark"
                content="删除产品"
                placement="top"
              >
                <p class="table-btn">
                  <i class="el-icon-delete-solid"></i>
                </p>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="pageChange"
          :page-sizes="[10, 50, 100, 150, 200]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
    </div>
    <operationProblem :dialogVisible="dialogVisible" :con="con" @outClose="dialogVisible = false" />
  </div>
</template>

<script>
import operationProblem from "./components/operationProblem.vue";
import { getList, delProblem } from "@/api/problem";
export default {
  components: {
    operationProblem
  },
  inject: ['reload'],
  data() {
    return {
      total: 0,
      pageNum: 1,
      pageSize: 10,
      item: [],
      tableData: [],
      classifyList: [],
      dialogVisible: false,
      con: {}
    };
  },
  created() {
    this.getList();
  },
  watch: {
    dialogVisible(data) {
      if (!data) {
        this.getList();
      }
    }
  },
  methods: {
    saveProblem() {
      this.dialogVisible = true;
    },
    handleSizeChange(e) {
      this.pageSize = e;
      this.getList();
    },
    pageChange(e) {
      this.pageNum = e;
      this.getList();
    },

    editNews(data) {
      this.con = JSON.parse(JSON.stringify(data));
      this.dialogVisible = true;
    },
    delList(id) {
      let that = this;
      this.$confirm("您正在删除产品, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          delProblem(id).then(res => {
            console.log(res);
            that.dialogVisible = false;
            that.$message.success("删除成功");
            that.reload();
          });
        })
        .catch(() => {
          that.$message.info("删除失败");
        });
    },
    async getList() {
      await getList().then(res => {
        res.data.forEach(list => {
            if(list.map_list) {
              list.map_list = JSON.parse(list.map_list)
            }
        });
        this.tableData = res.data;
      });
    }
  }
};
</script>

<style lang="less" scoped>
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}

.search {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  .left {
    width: 60%;
    display: flex;
    align-items: flex-end;
    .box {
      display: flex;
      /deep/ .el-input {
        margin-right: 20px;
        //   margin-left: 20px;
      }
    }
    /deep/ .el-select {
      width: 250px;
      margin-right: 20px;
    }
    .el-button {
      margin-left: 20px;
    }
    .input-with-select {
      width: 600px;
    }
  }
  .right {
    // width: 40%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    .box {
      display: flex;
      align-items: center;
    }
  }
}

.table {
  margin-top: 20px;
  .shop {
    display: flex;
    align-items: center;
    img {
      width: 40px;
      height: 40px;
      margin-right: 5px;
    }
    p {
      position: relative;
    }
  }
}
</style>